import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CAnchorNav,
  CFaqList,
  LPlan,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'faq',
            ja: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/access/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02 u_pt0_sp">
        <LWrap>
          <CAnchorNav
            data={[
              {
                link: {
                  href: '/faq#stay',
                },
                label: '宿泊について',
              },
              {
                link: {
                  href: '/faq#restaurant',
                },
                label: 'レストランについて',
              },
              {
                link: {
                  href: '/faq#access',
                },
                label: 'アクセスについて',
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="u_mbExLarge" id="stay">
        <LWrap>
          <h2 className="c_headingLv2">宿泊について</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: 'チェックイン・チェックアウトは何時ですか?',
                a: (
                  <>
                    チェックインは15:00から、チェックアウトは11:00まででございます。（プランにより異なる場合がございます）
                  </>
                ),
              },
              {
                q: '喫煙ルームはありますか?',
                a: (
                  <>
                    喫煙ルームはございません。ロビーの喫煙所をご利用ください。
                  </>
                ),
              },
              {
                q: '子供料金はありますか?',
                a: (
                  <>
                    未就学のお子様がベッドを使用しない(添い寝）場合、宿泊料金は基本かかりません。（※ただし、お食事をご希望の場合は別途申し受けます。）なお、小学生以上のお子様については、大人と同額の宿泊料金がかかります。
                  </>
                ),
              },
              {
                q: '1室何名まで泊まれますか?',
                a: (
                  <>
                    客室タイプにより異なります。各客室ページの「添い寝を含めた最大人数」をご参照ください。
                    <br />
                    <Link to="/stay/guestroom/">客室のご案内</Link>
                  </>
                ),
              },
              {
                q: '支払い方法について教えてください',
                a: (
                  <>
                    当ホテルでは事前精算とさせていただいております。事前振込もしくはチェックイン時にご精算いただき、追加ご利用についてはチェックアウト時にお支払いいただきます。お支払いは現金、クレジットカード、デビットカード、QR決済（PayPay、auPay、MerPay、D払い、楽天ペイ、Alipay、WeChatPay、LINEPay）がご利用いただけます。その他、ご利用可能なカード会社発行商品券は三菱地所グループ共通ギフトカード、三菱ＵＦＪニコスギフトカード、ＪＣＢギフトカード券となります。お支払いの際にホテルスタッフへお申し付けください。
                  </>
                ),
              },
              {
                q: 'ホテルへ事前に荷物を送れますか?',
                a: (
                  <>
                    ご宿泊されるお客様のお名前とチェックイン日をご記入のうえ、お送りください。チェックイン当日までお預かりいたします。
                  </>
                ),
              },
              {
                q: '到着が遅れそうな場合（遅れる場合）は連絡した方がいいですか?規定はありますか?',
                a: (
                  <>
                    事前にお知らせいただいたご到着予定時刻より２時間以上遅れる場合は、お電話にてご一報をお願いいたしております。お客様からのご連絡がない場合、宿泊日当日の20:00（あらかじめ到着予定時刻が明示されている場合は、その時刻を２時間経過した時刻）になっても到着されない場合は、その宿泊契約はお客様により解除されたものとみなし処理する場合がございます。
                  </>
                ),
              },
              {
                q: 'お庭を眺められるお部屋はありますか？',
                a: (
                  <>
                    ガーデンに面した南向きの客室からご覧いただけます。下記をご参照ください。
                    <br />
                    <Link to="/stay/guestroom/#gardenside">
                      ガーデンサイドのお部屋のご案内
                    </Link>
                  </>
                ),
              },
              {
                q: 'アメニティは何がありますか?',
                a: (
                  <>
                    アメニティは、シャンプー・コンディショナー・ハブラシ・ボディソープ・ハンドソープ・シェーバー・ヘアブラシ・使い捨てスリッパ・コットン・綿棒セット、客室設備・備品として、空気洗浄加湿器・空調設備・ウォッシュレット・セーフティーボックス・シューシャインクロス・シューホーン・洋服ブラシ・ドライヤー・電気ポット・紅茶・緑茶・ルームウェアをご用意いたしております。
                  </>
                ),
              },
              {
                q: '車椅子で利用できるお部屋はありますか?',
                a: (
                  <>
                    ラグジュアリーツイン（37㎡）と同じ広さのユニバーサルルーム（バリアフリー）が1室ございます。浴室・化粧室の造りが異なり、車椅子のまま入室いただけます。
                    <br />
                    <Link to="/stay/guestroom/universalroom/">
                      ユニバーサルルームのご案内
                    </Link>
                  </>
                ),
              },
              {
                q: 'コネクティングルームはありますか?',
                a: (
                  <>
                    ラグジュアリーファミリールーム（37㎡）・ラグジュアリーツイン（37㎡）をコネクティングルームとしてご利用いただけます。
                    <br />
                    <Link to="/stay/guestroom/luxuryfamilyroom/">
                      ラグジュアリーファミリールームのご案内
                    </Link>
                    <br />
                    <Link to="/stay/guestroom/luxurytwin/">
                      ラグジュアリーツインのご案内
                    </Link>
                  </>
                ),
              },
              {
                q: 'ルームサービスはありますか?',
                a: (
                  <>申し訳ございませんが、ルームサービスは承っておりません。</>
                ),
              },
              {
                q: 'ルームウェアーはありますか?',
                a: <>各客室にご用意しております。</>,
              },
              {
                q: '自動販売機はありますか?',
                a: (
                  <>
                    ロビーフロア（ウェディングサロン隣）に、食品・ソフトドリンク・酒類の自動販売機を設置しております。※酒類の販売にはカードキーが必要となりますので、フロントスタッフまでお申し付けください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge" id="restaurant">
        <LWrap>
          <h2 className="c_headingLv2">レストランについて</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '予約は必要ですか?',
                a: (
                  <>
                    ランチおよびディナーメニューは一部を除き完全予約制ではないためフリーでのご利用も可能ですが、お日にちよっては混み合うことがございます。予定がお決まりの場合は、早めのご予約をおすすめいたします。
                  </>
                ),
              },
              {
                q: 'アレルギーメニューには対応してもらえますか?',
                a: (
                  <>
                    ご予約時およびご注文の際、スタッフにお問い合わせください。
                  </>
                ),
              },
              {
                q: '誕生日などの記念日に利用したいのですが、何か特別な演出をお願いできますか?',
                a: (
                  <>
                    当ホテルでは、お客様の記念日がより良い日になるようお手伝いをさせていただいております。ケーキや花束のお手配、サプライズ演出などご要望がございましたら、お気軽にご相談ください。
                  </>
                ),
              },
              {
                q: 'レストランのオープン時間を教えてください。',
                a: (
                  <>
                    各レストランページをご参照ください。
                    <br />
                    <Link to="/restaurants/">営業時間のご案内</Link>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge" id="access">
        <LWrap>
          <h2 className="c_headingLv2">アクセスについて</h2>
          <CFaqList
            hash=""
            data={[
              {
                q: '駐車場はありますか?また、駐車料金はいくらですか?',
                a: (
                  <>
                    ホテル敷地内の平面駐車場と地下駐車場をご利用いただけます。また、満車時は敷地外にある臨時駐車場をご案内する場合もございます。これらの駐車場は、ご滞在中は無料です。詳しくはアクセスページをご参照ください。
                    <br />
                    <Link to="/access/">アクセスページ</Link>
                  </>
                ),
              },
              {
                q: '送迎バスはありますか?',
                a: (
                  <>
                    仙台駅と当ホテル間を運行するホテル専用シャトルバスがございます。
                    <br />
                    <Link to="/access/#bus">アクセスページ</Link>
                  </>
                ),
              },
              {
                q: '仙台駅からのタクシー料金は?また最寄の泉中央駅からのタクシー料金は?',
                a: (
                  <>
                    当日の道路状況により誤差はございますが、仙台駅から約4,000円前後（所要時間約30分）、地下鉄泉中央駅からは約1,850円前後（所要時間約15分）が目安となります。
                  </>
                ),
              },
              {
                q: '有名観光地までの所要時間やアクセスを教えてください。',
                a: (
                  <>
                    松島・平泉・山寺・蔵王がお車で1時間ほどの距離にあります。高速道路のインターチェンジも近くにあり、お車での移動に便利です。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
